import Vue from 'vue';
import Router from 'vue-router';
import routeBuilder from './routeBuilder';
import auth from '@/auth';

const Callback = () => import('@/views/callback');
const DefaultContainer = () => import('@/containers/DefaultContainer');
const Dashboard = () => import('@/views/Dashboard');
const Profile = () => import('@/views/Profile');
const Page404 = () => import('@/views/Sites/Page404');
const Page500 = () => import('@/views/Sites/Page500');
const Login = () => import('@/views/Sites/Login');
const Forgot = () => import('@/views/Sites/Forgot');
const Reset = () => import('@/views/Sites/Reset');
const Register = () => import('@/views/Sites/Register');
const Stats = () => import('@/views/Stats');

Vue.use(Router);

/*
  const Channel = routeBuilder('Channel', false);
  Channel.children.push({
    path: 'migration',
    name: 'Migration',
    component: () => import('@/views/Channel/Migration'),
  });
*/

const Order = routeBuilder('Order', false);
// Order.children.push({
//   path: 'isystem',
//   name: 'Isystem',
//   component: () => import('@/views/Order/Isystem'),
// });
Order.children.push({
  path: 'dhl',
  name: 'DHL',
  component: () => import('@/views/Order/Label/DHL'),
});
Order.children.push({
  path: 'sms',
  name: 'SMS',
  component: () => import('@/views/Order/SMS'),
});
Order.children.push({
  path: 'lastStockOrderItems',
  name: 'Last Stock Order Items',
  component: () => import('@/views/Order/LastStockOrderItems'),
});
Order.children.push({
  path: 'orderItemExport',
  name: 'Export: Order Items',
  component: () => import('@/views/Order/OrderItemExport'),
});
Order.children.push({
  path: 'orderExport',
  name: 'Export: Orders',
  component: () => import('@/views/Order/OrderExport'),
});
Order.children.push({
  path: 'orderMessage/:id/view',
  name: 'Order Message',
  component: () => import('@/views/Order/OrderMessage'),
});

const Product = routeBuilder('Product', false);
Product.children.push({
  path: 'mass',
  name: 'Product Mass',
  component: () => import('@/views/Product/Mass'),
});
Product.children.push({
  path: 'random',
  name: 'Product Random',
  component: () => import('@/views/Product/Random'),
});
// Product.children.push({
//   path: 'stocks',
//   name: 'Product Stocks',
//   component: () => import('@/views/Product/Stocks'),
// });

// const ProductReview = routeBuilder('Product Review');
// const ProductFamily = routeBuilder('Product Family');
// const MaterialSample = routeBuilder('Material Sample');
const IdlePayment = {
  path: `/idle-payments`,
  name: 'IdlePayments',
  component: () => import('@/views/IdlePayment/IdlePayments'),
};

const ModuleGroup = routeBuilder('Module Group', false);
ModuleGroup.children.push({
  path: 'module_group_item',
  name: 'Module Group Item',
  component: () => import('@/views/ModuleGroup/ModuleItem'),
});
ModuleGroup.children.push({
  path: 'module_search',
  name: 'Module Search',
  component: () => import('@/views/ModuleGroup/ModuleSearch'),
});

/*
  const StockPool = routeBuilder('Stock Pool', false);

  StockPool.children.push({
    path: 'product_stocks',
    name: 'Product Stocks',
    component: () => import('@/views/StockPool/ProductStocks'),
  });
  StockPool.children.push({
    path: 'product_stock_logs',
    name: 'Product Stock Logs',
    component: () => import('@/views/StockPool/ProductStockLogs'),
  });

  StockPool.children.push({
    path: 'manufacturer_delivery_dates',
    name: 'Manufacturer Delivery Dates',
    component: () => import('@/views/StockPool/ManufacturerDeliveryDates'),
  });
*/

/*
  const StockSourceFileDownload = routeBuilder(
    'Stock Source File Download',
    false
  );
  StockSourceFileDownload.children.push({
    path: 'manual-stock-file-upload',
    name: 'Manual Stock File Upload',
    component: () =>
      import('@/views/StockSourceFileDownload/ManualStockFileUpload'),
  });
*/

/*
  const Category = routeBuilder('Category', true);

  Category.children.push({
    path: 'tree',
    name: 'Category Tree',
    component: () => import('@/views/Category/Tree'),
  });
  Category.children.push({
    path: 'tags',
    name: 'Category Tags',
    component: () => import('@/views/Category/Tags'),
  });
  Category.children.push({
    path: 'stocks',
    name: 'Category Stocks',
    component: () => import('@/views/Category/Stocks'),
  });
  Category.children.push({
    path: 'mappings',
    name: 'Category Mappings',
    component: () => import('@/views/Category/Mappings'),
  });
  Category.children.push({
    path: 'clone',
    name: 'Category Clone',
    component: () => import('@/views/Category/Clone'),
  });
*/

/*
  const ProductFilter = routeBuilder('Product Filter', false);
  ProductFilter.children.push({
    path: 'results',
    name: 'Product Filter Results',
    component: () => import('@/views/ProductFilter/Results'),
  });
  ProductFilter.children.push({
    path: 'results',
    name: 'Product Filter Results Generate',
    component: () => import('@/views/ProductFilter/ResultsGenerate'),
  });
*/
const ProductAttribute = routeBuilder(
  'Product Attribute',
  false,
  '/settings/products'
);
ProductAttribute.children.push({
  path: 'mappings',
  name: 'Product Attribute Mappings',
  component: () => import('@/views/ProductAttribute/Mappings'),
});
ProductAttribute.children.push({
  path: 'attribute_value_mappings/:id',
  name: 'Product Attribute Value Mappings',
  params: {
    id: null,
  },
  component: () => import('@/views/ProductAttribute/AttributeValueMappings'),
});

const ProductRemoteAttribute = routeBuilder('Product Remote Attribute', false);

/*
  const ManufacturerDelivery = routeBuilder('Manufacturer Delivery', false);
  ManufacturerDelivery.children.push({
    path: 'bulk',
    name: 'Bulk Create Manufacturer Delivery',
    component: () => import('@/views/ManufacturerDelivery/Bulk'),
  });
  ManufacturerDelivery.children.push({
    path: 'bulkDelete',
    name: 'Bulk Delete Manufacturer Delivery',
    component: () => import('@/views/ManufacturerDelivery/BulkDelete'),
  });
*/

const StaticSiteUrl = routeBuilder('Static Site Url', false);
// StaticSiteUrl.children.push({
//   path: 'bulk',
//   name: 'Bulk Create Manufacturer Delivery',
//   component: () => import('@/views/StaticSiteUrl/Bulk'),
// });

const Inspiration = routeBuilder('Inspiration', true);
Inspiration.children.push({
  path: 'stats',
  name: 'Inspiration Stats',
  component: () => import('@/views/Inspiration/Stats'),
});
Inspiration.children.push({
  path: 'tags',
  name: 'Inspiration Tags',
  component: () => import('@/views/Inspiration/Tags'),
});

/*
  const InspirationImage = routeBuilder('Inspiration Image', true);
  InspirationImage.children.push({
    path: 'stats',
    name: 'Inspiration Image Stats',
    component: () => import('@/views/InspirationImage/Stats'),
  });
  InspirationImage.children.push({
    path: 'tags',
    name: 'Inspiration Image Tags',
    component: () => import('@/views/InspirationImage/Tags'),
  });
  InspirationImage.children.push({
    path: 'mass',
    name: 'Inspiration Image Mass',
    component: () => import('@/views/InspirationImage/Mass'),
  });
  InspirationImage.children.push({
    path: 'random',
    name: 'Inspiration Image Random',
    component: () => import('@/views/InspirationImage/Random'),
  });
  InspirationImage.children.push({
    path: 'dropbox',
    name: 'Dropbox Folders',
    component: () => import('@/views/InspirationImage/DropboxFolders'),
  });
*/

function requireAuth(to, from, next) {
  auth.loggedIn().then((response) => {
    if (!response) {
      next({
        path: '/sites/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  });
}

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      beforeEnter: requireAuth,
      children: [
        // Channel,
        routeBuilder('Flag', false),
        // routeBuilder('Teaser', false, '/settings'),
        routeBuilder('Fomo', false),
        // routeBuilder('Customer', true),
        // routeBuilder('Showroom', false),
        routeBuilder('Reminder', false),
        routeBuilder('Influencer', false),
        routeBuilder('Kpi', false),
        routeBuilder('Audit', false),
        Product,
        // ProductFamily,
        // ProductReview,
        // MaterialSample,
        // ProductFilter,
        IdlePayment,
        routeBuilder('PromotionBar'),
        routeBuilder('Promotion', true),
        // routeBuilder('Coupon', true),
        // routeBuilder('Manufacturer', false),
        // ManufacturerDelivery,
        StaticSiteUrl,
        Inspiration,
        // InspirationImage,
        routeBuilder('Design World', false),
        Order,
        // routeBuilder('Tag', false, '/settings/products'),
        ProductAttribute,
        ProductRemoteAttribute,
        routeBuilder('Attribute Group', false, '/settings/products'),
        // Category,
        routeBuilder('Interesting Find', false, '/settings/products'),
        // routeBuilder('Link', false, '/settings'),
        // routeBuilder('MegaMenu', false, '/settings'),
        // routeBuilder('Language', false, '/settings'),
        // routeBuilder('Currency', false, '/settings'),
        // routeBuilder('Payment Method', false, '/settings'),
        // routeBuilder('Delivery Method', false, '/settings'),
        // routeBuilder('Customer Group', false, '/settings'),
        // routeBuilder('Tax', false, '/settings'),
        // routeBuilder('Template', false, '/settings'),
        // routeBuilder('Country', false, '/settings'),
        // routeBuilder('Import', true, '/settings'),
        routeBuilder('Remote Category', false),
        // routeBuilder('Carrier', false, '/settings'),
        // routeBuilder('Partner', false, '/settings'),
        // routeBuilder('Campaign', false, '/settings'),
        // routeBuilder('Order Status', false, '/settings'),
        // routeBuilder('Trendshow', false, '/settings/collections'),
        routeBuilder('Collection', false, '/settings/collections'),
        // routeBuilder('User', false),
        routeBuilder('Role', false),
        // routeBuilder('Promotion Bar', false),
        routeBuilder('Error Message', false, '/settings'),
        // StockPool,
        // routeBuilder('Stock Source File', false),
        // StockSourceFileDownload,
        routeBuilder('Slug', false),
        // routeBuilder('Page', false),
        routeBuilder('Remote Attribute Value', false),
        // routeBuilder('Export', false),
        routeBuilder('MailchimpList', false),
        ModuleGroup,
        {
          path: 'cache',
          name: 'Cache Control',
          component: () => import('@/views/Cache/Cache'),
        },
        {
          path: 'baselinker',
          name: 'Baselinker',
          component: () => import('@/views/Baselinker/Baselinker'),
        },
        {
          path: 'reports/orders',
          name: 'Orders Reports',
          component: () => import('@/views/Reports/Orders'),
        },
        {
          path: 'reports/basket-products',
          name: 'Basket Products Reports',
          component: () => import('@/views/Reports/BasketProducts'),
        },
        {
          path: 'settings/profile',
          name: 'Profile',
          component: Profile,
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'stats',
          name: 'Stats',
          component: Stats,
        },
      ],
    },
    {
      path: '/categories',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/categories';
      },
    },
    {
      path: '/promotion_bars',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/products/promotions/bars';
      },
    },
    {
      path: '/product_filters',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/product-filters';
      },
    },
    {
      path: '/manufacturer_deliveries',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/manufacturer-deliveries';
      },
    },
    {
      path: '/coupons',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/products/coupons';
      },
    },
    {
      path: '/pages',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/pages';
      },
    },
    {
      path: '/inspiration_images',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/inspiration-images';
      },
    },
    {
      path: '/exports',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/exports';
      },
    },
    {
      path: '/stock_pools',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/stock-pools';
      },
    },
    {
      path: '/stock_source_files',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/stock-source-files';
      },
    },
    {
      path: '/stock_source_file_downloads',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/stock-source-file-downloads';
      },
    },
    {
      path: '/categories/tree',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/categories/tree';
      },
    },
    {
      path: '/categories/mappings',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/categories/mappings';
      },
    },
    {
      path: '/settings/imports',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/imports';
      },
    },
    {
      path: '/users',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/users';
      },
    },
    {
      path: '/settings/teasers',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/teasers';
      },
    },
    {
      path: '/settings/links',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/links';
      },
    },
    {
      path: '/channels',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/channels';
      },
    },
    {
      path: '/channels/migration',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/channels/migration';
      },
    },
    {
      path: '/showrooms',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/showrooms';
      },
    },
    {
      path: '/settings/order_statuses',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/order-statuses';
      },
    },
    {
      path: '/settings/payment_methods',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/payment-methods';
      },
    },
    {
      path: '/settings/templates',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/templates';
      },
    },
    {
      path: '/material_samples',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/products/materialSamples';
      },
    },
    {
      path: '/settings/delivery_methods',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/delivery-methods';
      },
    },
    {
      path: '/manufacturers',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/manufacturers';
      },
    },
    {
      path: '/customers',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/customers';
      },
    },
    {
      path: '/settings/customer_groups',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/customer-groups';
      },
    },
    {
      path: '/product_reviews',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/products/reviews';
      },
    },
    {
      path: '/settings/megamenus',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/menu';
      },
    },
    {
      path: '/settings/products/tags',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/tags';
      },
    },
    {
      path: '/settings/products/tags/all',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/tags';
      },
    },
    {
      path: '/product_families',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/product-families';
      },
    },
    {
      path: '/settings/taxes',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/taxes';
      },
    },
    {
      path: '/settings/collections/trendshows',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/trendshows';
      },
    },
    {
      path: '/settings/currencies',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/currencies';
      },
    },
    {
      path: '/settings/languages',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/languages';
      },
    },
    {
      path: '/settings/countries',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/countries';
      },
    },
    {
      path: '/settings/carriers',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/carriers';
      },
    },
    {
      path: '/settings/partners',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/partners';
      },
    },
    {
      path: '/settings/campaigns',
      beforeEnter(to, from, next) {
        window.location.href =
          (process.env.VUE_APP_NEW_BACKOFFICE_URL.replace(/\/$/, '') ||
            'http://www.example.org') + '/campaigns';
      },
    },
    {
      path: '/sites',
      redirect: '/sites/404',
      name: 'Sites',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404,
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
        },
        {
          path: 'login',
          name: 'Login',
          component: Login,
          // beforeEnter: onlyForLoggedOut
        },
        {
          path: 'forgot',
          name: 'Forgot',
          component: Forgot,
        },
        {
          path: 'reset',
          name: 'Reset',
          component: Reset,
        },
        {
          path: 'logout',
          name: 'Logout',
          beforeEnter(to, from, next) {
            auth.logout();
            next('/');
          },
        },
        {
          path: 'register',
          name: 'Register',
          component: Register,
        },
      ],
    },
    {
      path: '/callback',
      name: 'Callback',
      component: Callback,
    },
    {
      path: '**',
      redirect: '/sites/404',
    },
  ],
});

import VueGtag from 'vue-gtag';

if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID },
    },
    router
  );
}

export default router;
